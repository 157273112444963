.sorting-control {
    float: right;
    margin-right: 5px;
    width: 12px;
}

.sorting-control i {
    position: absolute;
    font-size: 18px;
}

.table-pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.table-pagination .page-size {
    height: 29px;
    padding: 4px 10px;
    font-size: 15px;

    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
}


.table-pagination .infos {
    display: inline-block;
    margin-left: 10px;

    color: #666;
    font-size: 14px;
}

.table-pagination .pagination {
    white-space: nowrap;
}

.table-pagination .pagination button {
    border: none;
    line-height: 1.42857143;
    cursor: pointer;
    font-size: 14px;

    color: #337ab7;

    padding: 4px 9px !important;
    background: #ddd !important;
    border-color: #ddd !important;
}

.table-pagination .pagination button.active {
    color: #333;
}

.table-pagination .pagination button:disabled {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}

.table-pagination .pagination button:first-child {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.table-pagination .pagination button:last-child {
    margin-right: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}