body {
  margin: 0;
  outline: 0;

  height: 100vh !important;

  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #444;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #444;
}

body * {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}